@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .uppercase {
    @apply tracking-wider;
    text-transform: uppercase;
  }

  .pdfPage {
    @apply w-full;
  }
  .pdfPage > canvas {
    @apply max-w-full;
  }
  .pdfPage > div {
    @apply hidden;
  }

  .arrow-left {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px;
    border-left-width: 0px;
    @apply border-gray-900;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  }
  .arrow-top {
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px;
    border-top-width: 0px;
    @apply border-gray-900;
    border-right-color: transparent;
    border-top-color: transparent;
    border-left-color: transparent;
  }
}

@layer base {
  :root {
    --primary: #00D382;
    --primary-hover: #00B36E;
    --primary-contrast: #01382E;
    --font-family: "Mulish";
  }

  body {
    font-family: var(--font-family), "sans-serif";
    @apply text-base font-regular text-gray-700;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-gray-900;
  }

  details summary::-webkit-details-marker { display: none; }

  .manymoreIcon * { vector-effect: non-scaling-stroke }

  .markdownStyles > h1 { @apply text-2xl font-extrabold text-gray-900; }
  .markdownStyles > h2 { @apply text-lg font-bold text-gray-900; }
  .markdownStyles > blockquote { @apply font-bold text-teal-900 text-center }
  .markdownStyles > ul { @apply list-inside list-disc; }
}


@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap");
